import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../../components/layout';
import { inline } from '../../components/layout.module.css';

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="Blog Posts">
      <p>Recent blog posts:</p>
      {data.allMdx.nodes.map((node) => (
        <article key={node.id} style={{marginBottom: 20}}>
            <h2 className={inline}>≫ <Link to={`/blog/${node.slug}`}>{node.frontmatter.name}</Link></h2>
            <p className={inline} style={{marginLeft: 8}}>{node.frontmatter.date}</p>
        </article>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query {
    allMdx(sort: {order: DESC, fields: frontmatter___date}) {
      nodes {
        frontmatter {
          name
          date(formatString: "MMMM D, YYYY")
        }
        body
        id
        slug
      }
    }
  }
`;

export default BlogPage;
